<template>
  <container ref="container" @error="init">
    <!-- 评论 -->
    <div class="evaluate">
      <!-- 评论-列表 -->
      <div class="evaluate-list">
        <!-- 评论-元素 -->
        <evaluate-card
          :detail="item"
          v-for="item in evaluate.list"
          :key="item.id"
        />
      </div>
    </div>
  </container>
</template>

<script>
import evaluateCard from "@/components/evaluate-card.vue";

export default {
  name: "nannyEvaluate",
  components: {
    evaluateCard,
  },
  data() {
    return {
      evaluate: {
        list: [],
      },
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.container.status("init", {
          height: "100vh",
        });

        // 获取数据
        const result = await this.$axios("/api/mainstation/witness", {
          params: {
            type: 2,
          },
        });

        // 设置数据
        this.evaluate.list = result.data;

        // 设置状态
        this.$refs.container.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  // 评价
  .evaluate {
    width: 1200px;
    margin: auto;

    // 企业-列表
    &-list {
      padding: 40px 0;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  // 评价
  .evaluate {
    width: 100%;

    // 企业-列表
    &-list {
      padding: rpx(20);
    }
  }
}
</style>